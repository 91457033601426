<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <back url="/resource/index" />
    <div class="chart-container m-t-sm" v-if="false">
      <div class="flex justify-between">
        <div class="flex items-center">
          <title-com text="视频截图检测" />
          <!-- <a-checkbox v-model="checkOnline" @change="onChangeStatus">
            只看在线
          </a-checkbox> -->
          <template v-if="false">
            <a-switch
              v-model="checkOnline"
              class="m-r-sm"
              @change="onChangeStatus"
            />
            只看在线
          </template>
        </div>
        <div></div>
        <a-button class="btn-export" v-if="false" @click="downloadHandle"
          >打包下载</a-button
        >
      </div>
      <div class="chart">
        <vue-chart
          ref="eChartDom"
          el="statusChart"
          v-if="showStackBar"
          @legendSelectChanged="legendSelectChangedHandle"
          @setClickEvent="setClickEventHandle"
          :options="barOptions"
        ></vue-chart>
        <vue-chart
          ref="eChartDom2"
          el="statusChart2"
          v-else
          :options="barOptions2"
          @setClickEvent="setClickEventHandle"
        ></vue-chart>
      </div>
    </div>
    <div class="flex items-center justify-between m-t-sm m-b-sm">
      <div class="flex items-center">
        <title-com text="视频情况明细" />
        <a-dropdown :trigger="['click']">
          <span class="ant-dropdown-link">
            {{ currentClickItemText }} <a-icon type="down" />
          </span>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="item in clickItem"
              :key="item.id"
              @click="handleOnlineAndOffline(item)"
            >
              {{ item.text }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="flex items-center">
        <!-- 按路线 -->

        <!-- <span class="span-line m-l-md m-r-md"></span> -->
        <a-button class="btn-export" @click="exportHandle">
          数据导出
        </a-button>
      </div>
    </div>
    <div class="table-con">
      <a-table
        :data-source="tableData"
        :columns="columns"
        rowKey="id"
        :loading="loading"
        :pagination="false"
        bordered
        :scroll="scroll"
      >
        <span
          slot="customRate"
          slot-scope="text"
          :style="{ color: getColor(text) }"
        >
          {{ text }}
        </span>
        <span slot="customOnlineDialog" slot-scope="text, row">
          <span
            class="pointer text-blue"
            @click="viewCameraInfo(row, '1', text)"
            >{{ text }}</span
          >
         <!--  <span>{{ text }}</span> -->
        </span>
        <span
          slot="customOnline"
          slot-scope="text"
          :style="{ color: getOnlineColor(text) }"
        >
          {{ text ? text : '/' }}
        </span>
        <span slot="customofflineQuantity" slot-scope="text, row">
          <span
            class="pointer text-blue"
            @click="viewCameraInfo(row, '0', text)"
            >{{ text }}</span
          >
          <!-- <span>{{ text }}</span> -->
        </span>
        <span
          slot="customOffline"
          slot-scope="text"
          :style="{ color: getOfflineColor(text) }"
        >
          {{ text ? text : '/' }}
        </span>
      </a-table>
    </div>
    <export-com-new
      :visible="dialogVisible"
      :downOrganizationId="dialogDownOrganizationId"
      :showOrganization="isShowOrganization"
      @sure="sureHandle"
      @cancel="cancelHandle"
    ></export-com-new>
    <!-- 摄像机信息弹窗 -->
    <camera-info-dialog
      :organizationId="currentOrganizationId"
      :onlineStatus="onlineStatus"
      :visible="cameraDialogVisible"
      @cancel="cancelCameraHandle"
    ></camera-info-dialog>
  </div>
</template>

<script>
import Back from '@/components/Back';
import { handleExportFile, deepClone } from '@/tools';
import TitleCom from '@/components/TitleCom';
import VueChart from '@/components/VueChart';
import ExportComNew from './components/ExportComNew.vue';
import CameraInfoDialog from './components/CameraInfoDialog.vue';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'NetCondition',

  data() {
    const columns = [
      {
        title: '排序',
        customRender: (text, record, index) => index + 1,
        width: 60,
      },
      {
        title: '业主单位',
        dataIndex: 'level-0-name',
        customRender: (value, row) => {
          let rowSpan = 0;
          if (
            row['level-0-name'] !== undefined &&
            row['level-0-index'] !== undefined
          ) {
            rowSpan = row['level-0-rowSpan'].value;
          }

          return {
            children: value,
            attrs: {
              rowSpan,
            },
          };
        },
        ellipsis: true,
      },
      {
        title: '业主单位',
        dataIndex: 'level-1-name',
        customRender: (value, row) => {
          let rowSpan = 0;
          if (
            row['level-1-name'] !== undefined &&
            row['level-1-index'] !== undefined
          ) {
            rowSpan = row['level-1-rowSpan'].value;
          } else if (row['level-0-rowSpan']?.value === 1) {
            rowSpan = 1;
          }
          return {
            children: value,
            attrs: {
              rowSpan,
            },
          };
        },
        ellipsis: true,
      },
      {
        title: '业主单位',
        dataIndex: 'level-2-name',
        customRender: (value, row) => {
          let rowSpan = 0;
          if (
            row['level-2-name'] !== undefined &&
            row['level-2-index'] !== undefined
          ) {
            rowSpan = row['level-2-rowSpan'].value;
          } else if (
            row['level-0-rowSpan']?.value === 1 ||
            row['level-1-rowSpan']?.value === 1
          ) {
            rowSpan = 1;
          }
          return {
            children: value,
            attrs: {
              rowSpan,
            },
          };
        },
        ellipsis: true,
      },
      {
        title: '业主单位',
        dataIndex: 'level-3-name',
        ellipsis: true,
      },
      {
        dataIndex: 'estimateQuantity',
        title: '应接入量',
        width: 100,
      },
      {
        dataIndex: 'realQuantity',
        title: '实际接入量',
        width: 100,
      },
      {
        dataIndex: 'accessedRate',
        title: '接入率',
        scopedSlots: { customRender: 'customRate' },
        width: 100,
      },
      {
        dataIndex: 'onlineQuantity',
        title: '在线数',
        scopedSlots: { customRender: 'customOnlineDialog' },
        width: 100,
        type: 'online',
      },
      {
        dataIndex: 'onlineRatio',
        title: '在线率',
        scopedSlots: { customRender: 'customOnline' },
        width: 100,
        type: 'online',
      },
      {
        dataIndex: 'offlineQuantity',
        title: '离线数',
        width: 100,
        type: 'offline',
        scopedSlots: { customRender: 'customofflineQuantity' },
      },
      {
        dataIndex: 'offlineRatio',
        title: '离线率',
        scopedSlots: { customRender: 'customOffline' },
        width: 100,
        type: 'offline',
      },
      {
        dataIndex: 'nowTime',
        title: '检测时间',
        ellipsis: true,
        width: 150,
      },
    ];

    return {
      columns,
      columnsTemp: columns,
      loading: true,
      tableData: [],
      scroll: { y: innerHeight - 260 },
      dialogVisible: false,
      cameraDialogVisible: false,
      currentClickItemText: '在线统计',
      currentClickItemType: 'online',
      currentOrganizationId: '',
      dialogDownOrganizationId:'',
      onlineStatus: '1',
      checkOnline: false,
      showStackBar:true,
      isShowOrganization:true,
      clickItem: [
        {
          id: 'online',
          text: '在线统计',
        },
        {
          id: 'offline',
          text: '离线统计',
        },
      ],
      barOptions: {
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        legend: {
          show: true,
          // orient: 'vertical',
          left: 'center',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },
        grid: {
          top: '15%',
          left: '2%',
          right: '2%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 1)',
            },
          },
          axisLabel: {
            rotate: -30,
            formatter: function(value) {
              return value.length > 5 ? value.substring(0, 5) + '...' : value;
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(134, 179, 233, 0.1)'],
              width: 1,
              type: 'solid',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisLabel: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },
        dataZoom: {
          type: 'inside',
          endValue: 20,
          startValue: 0,
          xAxisIndex: 0,
          zoomLock: true,
        },
        series: [
          {
            name: '在线',
            type: 'bar',
            stack: 'pro',
            itemStyle: {
              color: '#3A689F',
            },
            barWidth: '15px',
            data: [],
          },

          {
            name: '离线',
            type: 'bar',
            stack: 'pro',
            itemStyle: {
              color: '#ff6d55',
            },
            barWidth: '15px',
            data: [],
          },
        ],
      },
      dataSeriesTemp: [],
      dataSeries: [
        {
          name: '在线',
          type: 'bar',
          stack: '总量',
          itemStyle: {
            color: '#3A689F',
          },
          barWidth: '15px',
          data: [10, 20, 30],
        },

        {
          name: '离线',
          type: 'bar',
          stack: '总量',
          itemStyle: {
            color: '#ff6d55',
          },
          barWidth: '15px',
          data: [10, 20, 30],
        },
      ],
      barOptions2: {
        tooltip: {
          show: true,
          trigger: 'item',
        },
        legend: {
          show: true,
          // orient: 'vertical',
          left: 'center',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },
        grid: {
          top: '15%',
          left: '2%',
          right: '2%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 1)',
            },
          },
          axisLabel: {
            formatter: function(value) {
              return value.length > 5 ? value.substring(0, 5) + '...' : value;
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          data: ['在线数','离线数'],
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(134, 179, 233, 0.1)'],
              width: 1,
              type: 'solid',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisLabel: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },
        dataZoom: {
          type: 'inside',
          endValue: 20,
          startValue: 0,
          xAxisIndex: 0,
          zoomLock: true,
        },
        series:[
          {
            name:'摄像机在线离线情况',
            type: 'bar',
            barWidth: '15px',
            data: [
              {
              
                value:0,
                itemStyle:{
                  color:'#3A689F'
                },
                organizationId:'',
                seName:''
              },
              {
           
                value:0,
                itemStyle:{ 
                  color:'#ff6d55'
                }
              }
            ],
          },
          
        ]
      },
      // listData: [],
      // columnKey: [],
      // cols: [], //合并表格头
      // defColums: [
      //   {
      //     dataIndex: 'estimateQuantity',
      //     title: '应接入量',
      //   },
      //   {
      //     dataIndex: 'realQuantity',
      //     title: '实际接入量',
      //   },
      //   {
      //     dataIndex: 'accessedRate',
      //     title: '接入率',
      //     scopedSlots: { customRender: 'customRate' },
      //   },
      //   {
      //     dataIndex: 'onlineQuantity',
      //     title: '在线数量',
      //   },
      //   {
      //     dataIndex: 'onlineRatio',
      //     title: '在线率',
      //     scopedSlots: { customRender: 'customOnline' },
      //   },
      // ],
    };
  },
  components: {
    Back,
    TitleCom,
    VueChart,
    ExportComNew,
    CameraInfoDialog,
  },
  computed: {},
  watch: {},
  created() {
    this.columns = deepClone(this.columnsTemp).filter(
      (item) => item.type !== 'offline'
    );
    // console.log(this.columns);
    this.getTableData();
    //柱状图
    this._getCameraHistogram();
    /* this.$set(this.barOptions.xAxis, 'data', ['hello', 'how', 'you']);
    this.$set(this.barOptions, 'series', this.dataSeries);
    this.dataSeriesTemp = JSON.parse(JSON.stringify(this.dataSeries)); */
  },
  methods: {
    _getCameraHistogram() {
      this.$api.resource.getCameraHistogram().then((res) => {
        const { code, data } = res;
        if (code == CODE_OK) {
          //过滤掉摄像机数量等于0 的数据
          const newData = data.filter(item=>{
             return (item.onlineQuantity + parseInt(item.offlineQuantity))>0
          });
          if(newData.length>1){
            this.showStackBar = true;
            const organizationNameArr = newData.map((item) => item.organizationName);
            const onlineQuantityArr = newData.map((item) => ({value:item.onlineQuantity,organizationId:item.organizationId,seName:'online'}));
            const offlineQuantityArr = newData.map((item) => item.offlineQuantity);
            const barOptionsxAxis = this.barOptions.xAxis
            const barOptionsSeries0 =  this.barOptions.series[0];
            const barOptionsSeries1 = this.barOptions.series[1];
            this.$nextTick(() => {
              barOptionsxAxis.data = organizationNameArr;
              barOptionsSeries0.data = onlineQuantityArr;
              barOptionsSeries1.data = offlineQuantityArr;
            });
            this.isShowOrganization = true;
          }else if(newData.length ===1){
            const data = newData[0];
            this.showStackBar = false;
            const series = this.barOptions2.series[0];
            const seriesData0 = series.data[0];
            const seriesData1 = series.data[1];
            this.$nextTick(() => {
              series.name = `${data.organizationName}摄像机在线离线情况`;
              seriesData0.value = data.onlineQuantity;
              seriesData0.organizationId = data.organizationId;
              seriesData0.seName = 'online';
              seriesData1.value = data.offlineQuantity;
            });
            this.isShowOrganization = false;
          }
        }
      });
    },
    //柱状图点击事件
    setClickEventHandle(params) {
      console.log('pp',params)
      const seriesData = params.data;
      const {value,organizationId,seName} = seriesData
      if(seName === 'online' && value>0) {
        this.dialogVisible = true;
        this.dialogDownOrganizationId = organizationId;
      }
    },
    handleOnlineAndOffline(value) {
      this.currentClickItemText = value.text;
      this.currentClickItemType = value.id;
      this.columns = deepClone(this.columnsTemp).filter((item) =>
        value.id === 'online' ? item.type !== 'offline' : item.type !== 'online'
      );
    },
    downloadHandle() {
      this.dialogVisible = true;
    },
    cancelHandle() {
      this.dialogDownOrganizationId = '';
      this.dialogVisible = false;
    },
    //取消摄像机信息弹窗
    cancelCameraHandle() {
      this.cameraDialogVisible = false;
      this.currentOrganizationId = '';
      this.onlineStatus = '';
    },
    //查看摄像机信息
    viewCameraInfo(row, onlineStatus, text) {
      if (text <= 0) {
        return;
      }
      this.currentOrganizationId = row.organizationId;
      this.onlineStatus = onlineStatus;
      this.cameraDialogVisible = true;
    },
    sureHandle() {
      this.dialogVisible = false;
    },
    onChangeStatus(e) {
      // console.log(`checked = ${e.target.checked}`);
      // const checked = e.target.checked;
      const checked = e;
      this.checkOnline = checked;
      this.$refs['eChartDom'].getChart().dispatchAction({
        type: `${checked ? 'legendUnSelect' : 'legendSelect'}`,
        // 图例名称
        name: '离线',
      });
      this.$refs['eChartDom'].getChart().dispatchAction({
        type: 'legendSelect',
        // 图例名称
        name: '在线',
      });
    },
    //图例变化事件
    legendSelectChangedHandle(params) {
      let name = params.name;
      let offlineSelected = params['selected'][name];
      if (name === '离线') {
        this.checkOnline = !offlineSelected;
      } else if (name === '在线') {
        this.checkOnline = offlineSelected;
      }
    },
    getOnlineColor(text) {
      let ntext = parseInt(text);
      if (!isNaN(ntext)) {
        return `${
          ntext < 90
            ? '#D72828'
            : '#45CD8F'
        }`;
      } else {
        return '';
      }
    },
    getOfflineColor(text) {
      let ntext = parseInt(text);
      if (!isNaN(ntext)) {
        return `${
          ntext < 10
            ? '#45CD8F'
            : '#D72828'
        }`;
      } else {
        return '';
      }
    },
    getColor(text) {
      let ntext = parseInt(text);
      if (!isNaN(ntext)) {
        return `${
          ntext < 85
            ? '#D72828'
            : ntext >= 85 && ntext < 95
            ? '#F09C23'
            : '#45CD8F'
        }`;
      } else {
        return '';
      }
    },
    getArrowText(text) {
      let ntext = text
        ? Number(String(text).slice(0, String(text)[String(text).length - 1]))
        : -1;
      return `${ntext >= 0 ? '↑' : '↓'}`;
    },
    getArrowColor(text) {
      let ntext = Number(text);
      return `${ntext >= 0 ? '#FD4217' : '#45CD8F'}`;
    },
    exportHandle() {
      if (!this.hasPermission(109211101011)) {
        this.permissionWarning();
        return;
      }

      const _this = this;
      this.$confirm({
        title: '提示',
        content: '确定导出数据文件么?',
        onOk() {
          _this.$api.resource
            .exportCameraOrgStatistic(_this.currentClickItemType)
            .then((res) => {
              handleExportFile(res.data, '组织单位统计表.xls');
            });
        },
      });
    },
    // parseOrgData(orgs, level, p) {
    //   var k = 'organizationName_' + level;
    //   var size = 0;
    //   orgs.forEach(org => {
    //     if (
    //       org.organizationType == undefined ||
    //       parseInt(org.organizationType) <= 10
    //     ) {
    //       var param = {};
    //       var s = 0;
    //       for (let j in p) {
    //         param[j] = p[j];
    //         param['children'] = null;
    //       }
    //       // if (org.organizationType) {
    //       //   if (this.columnKey.indexOf(k) < 0) {
    //       //     this.columnKey.push(k);
    //       //   }
    //       //   param[k] = org.organizationName;
    //       // }
    //       if (this.columnKey.indexOf(k) < 0) {
    //         this.columnKey.push(k);
    //       }
    //       param[k] = org.organizationName;
    //       if (level < 3 && org.children && org.children.length > 0) {
    //         s += this.parseOrgData(org.children, level + 1, param);
    //       }
    //       if (org.organizationType <= 10 && s < 1) {
    //         if (param[this.columnKey[1]]) {
    //           //底层组织
    //           for (let j in param) {
    //             org[j] = param[j];
    //             org['children'] = null;
    //           }
    //           org.size = s;
    //           this.listData.push(org);
    //           s++;
    //         }
    //       }
    //       size += s;
    //     }
    //   });
    //   return size;
    // },
    // 处理表格数据
    arrangeData(data) {
      // 如果要表格展示任意层级数据，columns的判断最合适方式应这里递归2次，现在4层就行先这样吧，暂记
      const resultArray = [];
      let length = 0;
      const recursionLimit = 4; // 最多递归层数（最多取第4层业主数据来展示）
      recursion(data);
      // console.clear();
      console.log('resultArray', resultArray);
      this.tableData = resultArray;
      this.delYZDWCols(this.tableData);

      function recursion(children, parentNode = null, z = 0) {
        children.forEach((e, i) => {
          e.parent = parentNode;
          e.fcc = { value: 1 }; // 存放最终子元素个数
          e.levelInfo = {};
          e.levelInfo[`level-${z}-name`] = e.organizationName;
          e.levelInfo[`level-${z}-index`] = length;
          e.levelInfo[`level-${z}-rowSpan`] = e.fcc;
          resultArray[length] = Object.assign(
            resultArray[length] || {},
            e.levelInfo
          );

          if (e.children?.length > 0 && z < recursionLimit - 1) {
            recursion(e.children, e, z + 1);
          } else {
            e.fcc.value = 1;
            if (i === children.length - 1) {
              // 数组最后一个最终子节点
              gather(e);
            }
            [
              'estimateQuantity',
              'realQuantity',
              'accessedRate',
              'onlineQuantity',
              'onlineRatio',
              'offlineQuantity',
              'offlineRatio',
              'nowTime',
              'id',
            ].forEach((key) => {
              resultArray[length][key] = e[key];
              if (key === 'id') {
                resultArray[length]['organizationId'] = e[key];
              }
            });
            resultArray[length].id = `id-${length}`;
            length++;
          }
        });
      }

      function gather(node) {
        node.parent &&
          (node.parent.fcc.value = node.parent.children.reduce(
            (acc, e) => (acc += e.fcc?.value || 0),
            0
          ));
        node.parent && gather(node.parent);
      }
    },

    // 获取表格数据
    getTableData() {
      this.loading = true;
      this.$api.resource.getCameraOrgStatistic().then((res) => {
        this.loading = false;
        if (res.status === 200 && res.data.code === 200) {
          const node = this.findNode(res.data.data);
          // 如果是云南省交通运输厅9901004就不包括自身渲染，否则皆包括自身节点渲染(省平台不会出现交通运输部对应用户)
          this.arrangeData(node.id === '9901004' ? node.children : [node]);
          /* this.$nextTick(() => {
            this.columns = JSON.parse(JSON.stringify(this.columnsTemp)).filter(item=>item.type !== 'offline');
          }); */
        }
      });
    },

    // 获取指定节点
    findNode(data) {
      let findIt = false,
        node;
      const _this = this;
      return recursion(data);

      function recursion(children) {
        for (let i = 0; i < children.length; i++) {
          const curNode = children[i];
          if (
            !findIt &&
            curNode.id === _this.$store.state.login.userInfo.organizationId
          ) {
            node = curNode;
            findIt = true;
            return node;
          } else {
            if (curNode.children?.length > 0) {
              recursion(curNode.children);
            }
          }
        }

        return node;
      }
    },

    // 删除多余空 "业主单位" 列
    delYZDWCols(data) {
      let has2Col = false,
        has3Col = false,
        has4Col = false;

      for (let i = 0; i < data.length; i++) {
        if (!has2Col && data[i]['level-1-name'] && data[i]['level-1-rowSpan']) {
          has2Col = true;
        }
        if (!has3Col && data[i]['level-2-name'] && data[i]['level-2-rowSpan']) {
          has3Col = true;
        }
        if (!has4Col && data[i]['level-3-name'] && data[i]['level-3-rowSpan']) {
          has4Col = true;
        }
        if (has2Col && has3Col && has4Col) {
          break;
        }
      }

      !has4Col && this.columns.splice(4, 1);
      !has3Col && this.columns.splice(3, 1);
      !has2Col && this.columns.splice(2, 1);
    },

    // 获取表格数据
    // getTableData() {
    //   this.$api.resource.getCameraOrgStatistic().then((res) => {
    //     console.clear();
    //     // console.log('res,', res, res.data.data);
    //     if (res.status === 200 && res.data.code === 200) {
    //       this.parseOrgData(res.data.data, 0, {});
    //       this.spanArr = this.rowspan(0, 0); //合并第一列
    //       this.spanArrtwin = this.rowspan(1, 0); //合并第二列
    //       this.spanArrThree = this.rowspan(2, 0); //合并第三列
    //       let spanArrs = [this.spanArr, this.spanArrtwin, this.spanArrThree];
    //       let fun = (arr) => {
    //         return (value, row, index) => {
    //           const _row = arr[index];
    //           return {
    //             children: value,
    //             attrs: {
    //               rowSpan: _row,
    //             },
    //           };
    //         };
    //       };
    //       for (let i = 0; i < spanArrs.length; i++) {
    //         this.columns[i] = {
    //           ...this.columns[i],
    //           customRender: fun(spanArrs[i]),
    //         };
    //       }
    //       this.columns.unshift({
    //         title: '序号',
    //         customRender: (text, record, index) => index + 1,
    //         width: 70,
    //       });
    //     }
    //   });
    // },
    // rowspan(columnIndex, position) {
    //   let spanArr = [];
    //   position = 0;
    //   var _this = this;
    //   this.initColumn();
    //   this.listData.forEach((item, index) => {
    //     // console.log(item,index,'tab item')

    //     if (index === 0) {
    //       spanArr.push(1);
    //       position = 0;
    //     } else {
    //       // console.log()
    //       var k = _this.columnKey[columnIndex];
    //       // console.log(k,this.listData[index][k])
    //       if (this.listData[index][k] === this.listData[index - 1][k]) {
    //         spanArr[position] += 1;
    //         spanArr.push(0);
    //       } else {
    //         spanArr.push(1);
    //         position = index;
    //       }
    //     }
    //   });

    //   return spanArr;
    // },
    // // 表格配置项
    // // eslint-disable-next-line no-unused-vars
    // initColumn(colIndex, arr) {
    //   this.columns = [];

    //   this.columnKey.forEach(it => {
    //     let obj = { dataIndex: it, title: '业主单位' };
    //     this.columns.push(obj);
    //   });
    //   this.defColums.forEach(it => {
    //     this.columns.push(it);
    //   });

    //   // console.log(this.columns);
    // },
  },
};
</script>
<style scoped lang="less">
.full-width.full-height {
  ::v-deep .ant-table-wrapper {
    .ant-table-thead {
      tr {
        th {
          padding: 16px 2px;
          text-align: center;
        }
      }
    }

    .ant-table-body {
      .ant-table-tbody {
        tr {
          td {
            padding: 16px 2px;
            text-align: center;
          }
        }
      }
    }
  }
}
.chart {
  width: 100%;
  height: 200px;
}
.text-blue {
  color: #12b8ff;
}
.ant-dropdown-link{
  cursor: pointer;
}
</style>
