<template>
  <div class="chart" :id="el"></div>
</template>

<script>
import echarts from '@/tools/echarts';
export default {
  name: 'VueChart',
  props: {
    el: String,
    options: Object,
  },
  data() {
    return {
      vueChart:null
    };
  },
  computed: {},
  watch: {
     options: {
      handler() {
        this.$nextTick(() => {
          this.initChart();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    getChart() {
      return this.vueChart;
    },
    initChart() {
      let dom = document.getElementById(this.el);
      let _this = this;
      let chart = null;
      if (dom) {
        chart = echarts.init(dom);
        if (this.options) {
          chart.setOption(this.options, true);
        }
        this.vueChart = chart;
        //图例变化事件
        this.vueChart.on('legendselectchanged', function (params) {
            _this.$emit('legendSelectChanged',params);
        });
        //点击事件
        this.vueChart.on('click',function (params) {
          _this.$emit('setClickEvent',params);
        })
      }
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>
