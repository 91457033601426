<template>
  <div>
    <a-modal
      title="视频质量检测截图"
      width="80%"
      :visible="visible"
      :centered="true"
      dialogClass="videoCheck"
      :confirm-loading="confirmLoading"
      :footer="null"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="container">
        <div class="flex items-center m-b-md justify-between">
          <template>
            <div v-if="showOrganization">
              <span class="f-shrink title m-r-xs">路段单位：</span>
              <a-cascader
                :options="organizationList"
                :display-render="displayRender"
                class="search-mx-wd-1 m-r-sm"
                v-model="organizationId"
                expand-trigger="hover"
                placeholder="路段单位"
                @change="selectOrganization"
                v-if="false"
              />
              <a-select
                v-model="currentOrganizationId"
                allowClear
                show-search
                placeholder="选择一个管辖单位"
                option-filter-prop="children"
                class="search-mx-wd-1 m-r-sm"
                :filter-option="filterOption"
                @change="selectOrganization"
              >
                <a-select-option
                  v-for="item in organizationListByAllRoad"
                  :key="item.organizationId"
                  :value="item.organizationId"
                >
                  {{ item.organizationName }}
                </a-select-option>
              </a-select>
              <!--  <span class="f-shrink title m-r-xs" v-if="false">管辖单位：</span>
            <a-input
              v-model="roadOrganization"
              class="search-mx-wd-1 m-r-sm"
              placeholder="请输入路段名称"
              v-if=“false”
            /> -->
              <span class="f-shrink title m-r-xs" v-if="false">截图日期：</span>
              <a-select
                class="search-mx-wd-8 m-r-sm"
                v-model="selectDate"
                placeholder="请选择日期"
                @change="handleChangeDate"
                v-if="false"
              >
                <a-select-option
                  v-for="item in dateArr"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
              <a-select
                v-if="false"
                class="search-mx-wd-9 m-r-sm"
                v-model="selectMinuteStart"
                placeholder="开始时间"
                @change="handleChangeStartTime"
              >
                <a-select-option
                  v-for="item in minuteStartArr"
                  :key="item.value"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
              <a-select
                v-if="false"
                class="search-mx-wd-9 m-r-sm"
                v-model="selectMinuteEnd"
                placeholder="结束时间"
                @change="handleChangeEndTime"
              >
                <a-select-option
                  v-for="item in minuteEndArr"
                  :key="item.value"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
              <a-button type="primary" @click="searchHandle">搜索</a-button>
            </div>
            <span v-else>&nbsp;</span>
          </template>
          <a-button type="button" class="btn-export" @click="exportHandle"
            >导出</a-button
          >
        </div>
        <div class="date-select-wrap flex items-center m-b-sm">
          请选择日期:
          <span
            v-for="item in dateArr"
            :class="[
              'date-item m-l-xs m-r-xs pointer',
              { current: item.check },
            ]"
            @click="selectDateHandle(item)"
            :key="item.value"
            >{{ item.value }}</span
          >
        </div>
        <div class="content flex flex-wrap" ref="wrap">
          <div
            class="full-width full-height flex items-center justify-center"
            v-if="loading"
          >
            <a-spin tip="截图压缩中,请稍等..." />
          </div>
          <div
            class="full-width full-height flex items-center justify-center"
            v-else-if="!loading && !dataList.length"
          >
            <a-empty />
          </div>
          <template v-else>
            <div
              class="content-item flex flex-column items-center justify-center"
              :class="{ current: selectFolders.includes(item) }"
              v-for="(item, index) in dataList"
              :key="item.id"
              @click="selectFolder(item, index)"
            >
              <i class="folder"></i>
              <a-checkbox
                v-model="item.check"
                class="check-item"
                @change="(e) => onChangeSingle(e, item, index)"
              ></a-checkbox>
              <span class="ellipsis" :title="item.name">{{ item.name }}</span>
            </div>
          </template>
        </div>
        <div class="page">
          <a-pagination
            size="medium"
            :current="pagination.current"
            :total="pagination.total"
            :pageSize="pagination.pageSize"
            @change="onPaginationChange"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { generateTimeList, generateDate, padd, getTimeStamp } from '@/tools';
import { CODE_OK } from '@/request/config_code';
const myDate = new Date();
const CURRENT_DATE = padd(myDate.getMonth() + 1) + '-' + padd(myDate.getDate());
const CURRENT_START_TIME = '00:00';
const CURRENT_END_TIME = '23:30';
export default {
  name: 'ExportCom',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    downOrganizationId: {
      type: String,
      default: '',
    },
    showOrganization: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 21,
      },
      confirmLoading: false,
      organizationId: '',
      currentOrganizationId: '',
      roadOrganization: '',
      selectFolders: [],
      minuteStartArr: generateTimeList(30),
      minuteEndArr: generateTimeList(30),
      dateArr: [],
      selectDate: CURRENT_DATE,
      selectMinuteStart: CURRENT_START_TIME,
      selectMinuteEnd: CURRENT_END_TIME,
      dataList: [
        /*  {
          id: '1',
          check: false,
        },
        {
          id: '2',
          check: false,
        },
        {
          id: '3',
          check: false,
        },
        {
          id: '4',
          check: false,
        },
        {
          id: '5',
          check: false,
        },
        {
          id: '6',
          check: false,
        },
        {
          id: '7',
          check: false,
        },  */
      ],
      loading: false,
      tempFirstOrganizationId: [],
      organizationListByAllRoad: [],
    };
  },
  computed: {
    ...mapState({
      organizationList: (state) => state.collect.organizationList,
    }),
  },
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this.organizationId = this.downOrganizationId;
        this.getDateArr();
        //组织单位接口
        this._getOrganationListByOrg();
        this._getSnapShotZip();
      }
    },
  },
  created() {
    /* this.getOrganationList().then(({ data }) => {
      const list = formatOrganizationTree(data);
      const firstOrganizationId = this.getFirstOrganizationIds(list);
      this.tempFirstOrganizationId = firstOrganizationId;
      this.organizationId = firstOrganizationId;
    }); */
    this.formatDateTime();
  },
  methods: {
    ...mapActions(['getOrganationList']),
    //获取日期
    getDateArr() {
      this.dateArr = generateDate().map((item) => {
        if (item.value === CURRENT_DATE) {
          item.check = true;
        }
        return item;
      });
    },
    //获取下级组织单位
    _getOrganationListByOrg() {
      this.$api.snapshot
        .getAllRoadSecListByOrgId(this.downOrganizationId)
        .then((res) => {
          const { data, code } = res;
          if (code === CODE_OK) {
            this.organizationListByAllRoad = data;
          }
        });
    },
    //筛选选择框
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //选择日期
    selectDateHandle(item) {
      this.dateArr.forEach((item) => {
        item.check = false;
      });
      item.check = true;
      this.selectDate = item.value;
      this.pagination.current = 1;
      this._getSnapShotZip();
    },
    getFirstOrganizationIds(list) {
      let arr = [];
      let first = list[0];
      if (!first) {
        return arr;
      }
      arr.push(first.value);
      if (first.children && first.children.length) {
        arr.push(...this.getFirstOrganizationIds(first.children));
      }
      return arr;
    },
    _getSnapShotZip() {
      const year = new Date().getFullYear();

      const params = {
        orgId: /* this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '' */ this
          .organizationId,
        date: `${year}-${this.selectDate}`,
        currPage: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      this.loading = true;
      this.$api.snapshot.getSnapshotZips(params).then((res) => {
        const { code, data, total } = res;
        if (code == CODE_OK) {
          const dataList = data.map((item) => {
            item.id = item.name;
            this.$set(item, 'check', false);
            return item;
          });
          this.dataList = dataList;
          this.pagination.total = total;
          this.$nextTick(() => {
            this.setContentHt();
          });
          this.loading = false;
        } else {
          this.$message.error('查找截图失败');
          this.loading = false;
        }
      });
    },
    searchHandle() {
      this.pagination.current = 1;
      this._getSnapShotZip();
    },
    exportHandle() {
      // console.log('haha', this.dataList);
      if (!this.selectFolders.length) {
        this.$message.warning('请选择相应的zip包');
        return;
      }
      this.selectFolders.forEach((item) => {
        // window.open(item.url);
        this.downloadFile(item.url);
      });
    },
    downloadFile(url) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none'; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = url;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      setTimeout(() => {
        iframe.remove();
      }, 5 * 60 * 1000);
    },
    setContentHt() {
      const wrap = this.$refs.wrap;
      if (wrap) {
        wrap.style.height = `${window.innerHeight - 220}px`;
      }
    },
    onPaginationChange(pageNumber) {
      console.log('Page: ', pageNumber);
      this.pagination.current = pageNumber;
      this.selectFolders = [];
      this._getSnapShotZip();
    },
    formatDateTime() {
      this.minuteStartArr = this.minuteStartArr.map((item) => {
        item.disabled = item.timestamp >= getTimeStamp(this.selectMinuteEnd);
        return item;
      });
      this.minuteEndArr = this.minuteEndArr.map((item) => {
        item.disabled = item.timestamp <= getTimeStamp(this.selectMinuteStart);
        return item;
      });
    },
    handleChangeDate(value) {
      this.selectDate = value;
    },
    //时间控制
    handleChangeStartTime(value) {
      this.selectMinuteStart = value;
      this.formatDateTime();
    },
    handleChangeEndTime(value) {
      this.selectMinuteEnd = value;
      this.formatDateTime();
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value) {
      this.organizationId = value ? value : this.downOrganizationId;
      this.currentOrganizationId = value;
    },
    //单选
    onChangeSingle(e, item, index) {
      const check = e.target.checked;
      if (check) {
        this.$set(this.selectFolders, index, item);
      } else {
        this.$set(this.selectFolders, index, '');
      }
      // console.log(e,item);
    },

    //点击每个folder
    selectFolder(item, index) {
      if (this.selectFolders.includes(item)) {
        this.$set(this.selectFolders, index, '');
        item.check = false;
      } else {
        this.$set(this.selectFolders, index, item);
        item.check = true;
      }
      // console.log('hah', this.selectFolders);
    },
    resetData() {
      this.organizationId = '';
      this.currentOrganizationId = undefined;
      this.selectFolders = [];
      this.selectDate = CURRENT_DATE;
      this.pagination.current = 1;
    },
    handleOk() {
      this.resetData();
      this.$emit('sure');
    },
    handleCancel() {
      this.resetData();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .content-item {
    width: 151px;
    height: 151px;
    margin: 0 2px 5px 2px;
    padding: 5px 20px 15px;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
    .check-item {
      display: none;
      position: absolute;
      left: 5px;
      top: 5px;
    }
    &:hover,
    &.current {
      border-color: rgba(20, 144, 226, 0.897);
      background: rgba(20, 144, 226, 0.3);
      .check-item {
        display: block;
      }
    }

    .folder {
      display: block;
      width: 113px;
      height: 113px;
      background: url('~@/assets/resource/folder.svg') no-repeat;
      background-size: 100% 100%;
    }
    span {
      width: 96%;
    }
  }
}
.page {
  text-align: right;
  margin-top: 10px;
}
::v-deep .videoCheck {
  min-width: 1150px;
  max-width: 1150px;
}
.date-select-wrap {
  .date-item {
    border: 1px solid rgba(134, 179, 233, 1);
    padding: 0 10px;
    line-height: 28px;
    border-radius: 16px;
    &.current,
    &:hover {
      background: rgba(134, 179, 233, 1);
      color: #fff;
    }
  }
}
</style>
