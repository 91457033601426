<template>
  <div>
    <a-modal
      title="摄像机信息"
      width="74%"
      :visible="visible"
      :centered="true"
      :footer="null"
      :dialogStyle="dialogStyle"
      @cancel="handleCancel"
    >
      <div class="container">
        <div class="search-bar flex items-center justify-between m-b-sm">
          <div>
            <span class="f-shrink m-r-xs">路线：</span>
            <a-select
              placeholder="路线"
              class="search-mx-wd-1 m-r-sm"
              size="small"
              v-model="roadId"
              @change="selectRoad"
            >
              <a-select-option
                v-for="item in roadList"
                :key="item.roadId"
                :value="item.roadId"
              >
                {{ item.roadCode + ' ' + item.roadName }}
              </a-select-option>
            </a-select>
            <span class="f-shrink m-r-xs">位置类型：</span>
            <a-select
              placeholder="位置类型"
              class="search-mx-wd-1 m-r-sm"
              size="small"
              v-model="classify"
              @change="selectClassify"
            >
              <a-select-option
                  v-for="item in classifyList"
                  :key="item.codeValue"
                  :value="item.codeValue"
                >
                  {{ item.codeName }}
                </a-select-option>
            </a-select>
            <span class="flex-s m-r-xs">千米桩：</span>
            K
            <a-input
              placeholder="起始千米桩"
              size="small"
              v-model="kmPileMin"
              class="m-l-xs search-mx-wd-0"
            /><span class="m-l-xs m-r-xs">~</span>
            K
            <a-input
              placeholder="终止千米桩"
              size="small"
              class="m-l-xs search-mx-wd-0 m-r-sm"
              v-model="kmPileMax"
            />
            <a-button
              type="primary"
              class="m-r-sm btn-search"
              size="small"
              @click="searchFormHandle"
            >
              搜索
            </a-button>
            <a-button class="btn-reset" size="small" @click="resetFormHandle">
              重置
            </a-button>
          </div>
          <a-button size="small" class="btn-export" @click="exportHandle">
            数据导出
          </a-button>
        </div>
        <a-table
          :data-source="tableData"
          :columns="columns"
          :loading="loading"
          :pagination="paginationOpt"
          :rowKey="(record, index) => record.cameraNum"
          bordered
          :scroll="scroll"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <span
            slot="customRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span slot="customOnlineDialog" slot-scope="text">
            <span class="pointer">{{ text }}</span>
          </span>
          <span
            slot="customOnline"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import { handleExportFile } from '@/tools';
export default {
  name: 'CameraInfoDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    organizationId:{
      type:String,
      default: ''
    },
    onlineStatus:[Number,String]
  },
  data() {
    return {
      dialogStyle: { maxWidth: '1300px', maxHeight: `${window.innerHeight - 150}px`, height: '80%' },
      roadId: undefined,
      classify: undefined,
      kmPileMin: '',
      kmPileMax: '',
      tableData: [],
      selectedRowKeys: [],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          dataIndex: 'regionName',
          title: '地区',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'organizationName',
          title: '管辖单位',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'roadName',
          title: '所属路线',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'cameraName',
          title: '摄像机名称',
          width: 300,
          ellipsis: true,
        },
        {
          dataIndex: 'khPile',
          title: '桩号',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'longAndLat',
          title: '经纬度',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'derectionName',
          title: '方向',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'classify',
          title: '位置类型',
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'cameraNum',
          title: '摄像机编号',
          ellipsis: true,
          width: 150,
        },
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.getOrgCamera();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.getOrgCamera();
        },
      },
      loading: false,
      scroll: { y: window.innerHeight - 360,x:'100%'},
    };
  },
  computed: {
    ...mapState({
      roadList: (state) => state.collect.roadList,
      classifyList: (state) => state.collect.classifyList,
    }),
  },
  watch:{
    organizationId(nv,ov) {
      if(nv != ov){
        this.getOrgCamera();
      }
    }
  },
  created() {
    this.getRoadList();
    this.getClassifyList('CLASSIFY');
  },
  methods: {
    ...mapActions(['getRoadList','getClassifyList']),
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    getOrgCamera() {
      let params = {
        currPage:this.paginationOpt.current,
        pageSize:this.paginationOpt.defaultPageSize,
        roadId:this.roadId,
        classifyCode:this.classify,
        kmPileMin:this.kmPileMin,
        kmPileMax:this.kmPileMax,
        organizationId:this.organizationId,
        onlineStatus:this.onlineStatus,
      }
      this.loading = true;
      this.$api.resource.getOrgCameraData(params).then(res=>{
        const { code,data,total } = res;
        if(code === CODE_OK){
          this.tableData = data;
          this.paginationOpt.total = total;
          this.loading = false;
        }
      })
    },
    handleCancel() {
      //重置搜索框数据
      this.resetData();
      //向上提交事件
      this.$emit('cancel');
    },
    //选择路线
    selectRoad(e) {
      this.roadId = e;
    },
    //选择位置类型
    selectClassify(e) {
      this.classify = e;
    },
    //搜索
    searchFormHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.getOrgCamera();
    },
    resetData(){
      this.roadId = undefined;
      this.classify = undefined;
      this.kmPileMin = '';
      this.kmPileMax = '';
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.selectedRowKeys = [];
    },
    //重置
    resetFormHandle() {
      this.roadId = undefined;
      this.classify = undefined;
      this.kmPileMin = '';
      this.kmPileMax = '';
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.getOrgCamera();
    },
    //导出
    exportHandle() {
      let params = {
       /*  currPage:this.paginationOpt.current,
        pageSize:this.paginationOpt.defaultPageSize, */
        cameraList:this.selectedRowKeys,
        roadId:this.roadId,
        classifyCode:this.classify,
        kmPileMin:this.kmPileMin,
        kmPileMax:this.kmPileMax,
        organizationId:this.organizationId,
        onlineStatus:this.onlineStatus,
      }
       this.$api.resource.exportOrgCamera(params).then((res) => {
          handleExportFile(res.data, '组织单位导出摄像机列表.xls');
        });
    },
  },
};
</script>

<style scoped>
::v-deep .ant-modal-content {
  height: 100%;
}
::v-deep .ant-modal-body {
  height: calc(100% - 50px);
}
::v-deep .ant-table-bordered .ant-table-thead > tr > th, ::v-deep .ant-table-bordered .ant-table-tbody > tr > td {
    padding:10px;
}
</style>
